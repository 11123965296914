<template>
    <a-layout>
        <a-layout-content>
            <a-tabs default-active-key="1">
                <template slot="tabBarExtraContent">
                    <a-input-search placeholder="请输入查询内容" v-model="keyword" class="article-search" style="width: 200px" @search="onSearch"></a-input-search>
                    <a-button type="primary" size="small" class="del-article" @click="multiDelete">
                        <span class="iconfont icon-shanchu"></span>删除问题
                    </a-button>
                </template>
                <a-tab-pane key="1" tab="问题反馈"></a-tab-pane>
            </a-tabs>
            <div class="table-wrap">
                <a-table :data-source="articles" :rowKey="record=>record.id" :pagination="pageSetting" @change="changeTable" :row-selection="rowSelection">
                    <a-table-column key="id" title="序号" data-index="id" />
                    <a-table-column key="title" title="标题" data-index="title" />
                    <a-table-column key="content" title="问题内容" data-index="content">
                        <div slot-scope="text" v-html="text"></div>
                    </a-table-column>
                    <a-table-column key="createUserName" title="创建人" data-index="createUserName" />
                    <a-table-column key="createTime" title="创建时间" data-index="createTime" />
                    <a-table-column key="degree" title="紧急程度" data-index="degree">
                        <template slot-scope="text">
                            <span v-if="text">紧急</span>
                            <span v-else>正常</span>
                        </template>
                    </a-table-column>
                    <a-table-column key="solveStatus" title="日志状态" data-index="solveStatus">
                        <template slot-scope="text">
                            <a-tag color="red" v-if="text===0">
                                未解决
                            </a-tag>
                            <a-tag color="green" v-else>
                                已解决
                            </a-tag>
                        </template>
                    </a-table-column>
                    <a-table-column key="action" title="操作" data-index="action">
                        <template slot-scope="text,record">
                            <a-button type="link" @click="edit(record)">
                                回复
                            </a-button>
                            <a-button type="link" @click="deleteArticle(record.id)">
                                删除
                            </a-button>
                        </template>
                    </a-table-column>
                </a-table>
            </div>


            <a-modal 
                title="编辑问题"
                :visible="editVisible"
                centered
                :footer="null"
                :width="970"
                wrapClassName="edit-cube-win"
                @cancel="cancelEdit">

                <div class="inputs">
                    <a-row :gutter="[16,16]">
                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">问题标题</a-col>
                                <a-col flex="auto">
                                    <a-input v-model="formFields.title" :disabled="true" />
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>

                    <a-row :gutter="[16,16]">
                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">问题状态</a-col>
                                <a-col flex="auto">
                                    <a-radio-group v-model="formFields.status" style="height:34px;line-height:34px">
                                        <a-radio :value="0">未解决</a-radio>
                                        <a-radio :value="1">已解决</a-radio>
                                    </a-radio-group>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>             

                    <a-row :gutter="[16,16]">
                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">问题内容</a-col>
                                <a-col flex="auto">
                                    <div v-html="formFields.content"></div>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>

                    <a-row :gutter="[16,16]">
                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">问题内容</a-col>
                                <a-col flex="auto">
                                    <quill-editor :options="editorOption" :content="formFields.solveContent" @change="onEditorChange" style="height:150px" />
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>

                    <div style="height:60px;"></div>   

                </div>

                <div class="btns">
                    <a-button @click="cancelEdit">取消</a-button>
                    <a-button type="primary" @click="confirmEdit" :disabled="uploading">确定</a-button>
                </div>
            </a-modal>
        </a-layout-content>
    </a-layout>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { messageTips } from '../../until/tools';
import { quillEditor } from 'vue-quill-editor';
export default {
    name:'Articles',
    components:{quillEditor},
    data() {
        return {
            editVisible:false,
            selectedRowKeys:[],
            articles:[],
            keyword:'',
            page:{},
            rowSelection:{
                onChange: (selectedRowKeys) => {
                    this.selectedRowKeys = selectedRowKeys;
                },
                onSelect: (record, selected, selectedRows) => {
                    this.selectedRowKeys = selectedRows.map(item=>item.id);
                },
                onSelectAll: (selected, selectedRows) => {
                    this.selectedRowKeys = selectedRows.map(item=>item.id)
                },
            },
            editorOption: {
                placeholder: "请在这里输入",
                height:200,
                theme:'snow',
                modules:{
                    toolbar:[
                        ['bold', 'italic'],    //加粗，斜体，下划线，删除线
                        ['blockquote'],     //引用，代码块
                        [{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
                        [{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
                        [{ 'align': [] }],    //对齐方式
                        //['image']    //上传图片、上传视频
                    ]
                }
            },
            waitingEdit:null,
            uploading:false,
            formFields:{
                title:'',
                status:0,
                content:'',
                deal:'',
            }
        }
    },
    computed:{
        pageSetting(){
			let pageSize=0;
			if(this.page.allSize) {
				pageSize = this.page.allSize;
			}
			return {
				showQuickJumper:true,
				hideOnSinglePage:true,
				showTotal:()=>`共${pageSize}条`,
				total:pageSize,
			}
		},
    },
    created() {
        this.getList();
    },
    methods:{


        onSearch(e) {
            this.getList({currentPage:1,name:e});
        },
        changeTable(e) {
            this.getList({currentPage:e.current,name:this.keyword});
        },
        onEditorChange(e){
            this.formFields.solveContent = e.html;
        },
        getList(params) {
            if(params) {
                if(!params.queryType) {
                    params.queryType = 2;
                }
            } else {
                params = {
                    queryType:2
                }
            }
            this.$http.inputs_get_rebacks(params).then(res=>{
                if(res) {
                    if(res.list) {
                        this.articles = res.list;
                        this.page = res.page;
                    }
                }
            })
        },

        edit(item) {
            this.formFields = {
                id:item.id,
                title:item.title,
                content:item.content,
                status:item.solveStatus,
                solveContent:item.solveContent
            }
            this.editVisible = true;
        },
        confirmEdit() {
            if(this.formFields.title==='') {
                messageTips('请输入问题标题',2);
                return false;
            }
            if(this.formFields.content==='') {
                messageTips('请输入问题内容',2);
                return false;
            }
            let params = {
                id:this.formFields.id,
                solveStatus:this.formFields.status,
                solveContent:this.formFields.solveContent
            }
            this.$http.inputs_replay_reback(params).then(res=>{
                if(res) {
                    if(res.success) {
                        messageTips(res.message,3);
                        if(this.formFields.id) {
                            this.getList({currentPage:this.page.currentPage});
                        } else {
                            this.getList();
                        }
                        this.editVisible = false;
                        this.formFields = {
                            content:'',
                            title:'',
                            status:0
                        }
                    } else {
                        messageTips(res.message,1);
                    }
                }
            });
        },
        cancelEdit() {
            this.editVisible = false;
        },
        
        deleteArticle(ids) {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText: '确定',
                cancelText: '取消',
                centered:true,
                onOk() {
                    that.$http.inputs_delete_rebacks({ids}).then(res=>{
                        if(res) {
                            if(res.success) {
                                messageTips(res.message,3);
                                that.getList({currentPage:that.page.currentPage});
                            } else {
                                messageTips(res.message,1);
                            }
                        }
                    })
                }
            });
        },
        multiDelete() {
            if(this.selectedRowKeys.length===0) {
                messageTips('请选择要删除的数据',2);
                return false;
            }
            this.deleteArticle(this.selectedRowKeys.join(','));
        }
    }
}
</script>

<style lang="scss">
    @import '../../scss/articles.scss';
</style>